import { mcn } from "@/utils/mergeClassNames";
import { forwardRef, LegacyRef, useMemo } from "react";
import Label, { LabelProps } from "../Label";
import FieldMessage, { FieldMessageData } from "./FieldMessage";
import { Assert } from "@/utils/assert";

export type FieldState = "neutral" | "success" | "error";

type BaseProps = {
  labelProps?: LabelProps;
  /**
   * Set states explicit
   */
  state?: FieldState;
  messages?: {
    error?: (string | false)[];
    success?: (string | false)[];
    neutral?: (string | false)[];
  };
};

type InputAreaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
type ExtendedInputProps = BaseProps &
  InputAreaProps & {
    useTextArea?: false;
  };

type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;
type ExtendedTextAreaProps = BaseProps &
  TextAreaProps & {
    useTextArea: true;
  };

export type TextFieldProps = (ExtendedInputProps | ExtendedTextAreaProps) & {
  inputClassName?: string;
  type?: string;
};

const TextField = forwardRef<
  HTMLTextAreaElement | HTMLInputElement,
  TextFieldProps
>(
  (
    {
      labelProps,
      messages,
      state,
      className,
      inputClassName,
      useTextArea,
      type,
      ...rest
    }: TextFieldProps,
    ref
  ) => {
    const currentState: FieldState =
      state ||
      (messages?.error?.length && "error") ||
      (messages?.success?.length && "success") ||
      "neutral";

    const hasMessages =
      messages?.error?.length ||
      messages?.neutral?.length ||
      messages?.success?.length;

    const internalMessages = useMemo(() => {
      if (hasMessages) {
        const newMessages = [];
        const errorMessages =
          messages?.error
            ?.filter((x) => typeof x === "string")
            .map((x) => x as string)
            .map<FieldMessageData>((x) => ({ state: "error", text: x })) ?? [];
        const neutralMessages =
          messages?.neutral
            ?.filter((x) => typeof x === "string")
            .map((x) => x as string)
            .map<FieldMessageData>((x) => ({ state: "neutral", text: x })) ??
          [];
        const successMessages =
          messages?.success
            ?.filter((x) => typeof x === "string")
            .map((x) => x as string)
            .map<FieldMessageData>((x) => ({ state: "success", text: x })) ??
          [];

        if (errorMessages?.length) newMessages.push(...errorMessages);
        if (neutralMessages?.length) newMessages.push(...neutralMessages);
        if (successMessages?.length) newMessages.push(...successMessages);

        newMessages.sort((a, b) => (a.text > b.text ? 1 : -1));
        return newMessages;
      } else {
        return undefined;
      }
    }, [messages?.error, messages?.neutral, messages?.success, hasMessages]);

    const inputElemClassNames = mcn(
      "bg-white text-text-primary",
      "border rounded",
      "p-2",
      "input-default",
      "hover:input-hover",
      "active:input-active",
      "disabled:input-disabled",
      "focus-visible:input-focus",
      currentState !== "neutral" && `input-${currentState}`,
      inputClassName
    );
    return (
      <div className={mcn("flex flex-col", className)}>
        {labelProps && (
          <Label
            htmlFor={rest.id}
            {...labelProps}
            className={mcn(labelProps.className, "mb-1")}
          />
        )}
        {useTextArea ? (
          <textarea
            data-testid={"textfield-textarea"}
            {...(rest as TextAreaProps)}
            className={inputElemClassNames}
            ref={ref as LegacyRef<HTMLTextAreaElement>}
          />
        ) : (
          <input
            data-testid={"textfield"}
            type={type ? type : "text"}
            {...(rest as InputAreaProps)}
            ref={ref as LegacyRef<HTMLInputElement>}
            className={inputElemClassNames}
          />
        )}
        {internalMessages?.length ? (
          <div>
            {internalMessages.map((x) => {
              return (
                <FieldMessage
                  key={x.text}
                  message={x}
                  className="first:mt-xs"
                />
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
);

TextField.displayName = "TextField";

export default TextField;
